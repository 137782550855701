import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import router from './router';
import App from './App.vue';
import store from './store';
import VueI18n from './lang';
import SvgIcon from './utils/svgUtils/SvgIcon.vue';
import bus from 'vue3-eventbus'
// import bus from './utils/bus'
import 'animate.css';
import './assets/font/font.css';
// SVG解析
import './utils/svgUtils/svg';
import '@/assets/scss/handle.scss';
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  console.log(to)
  next()
})
//WebSocket封装方法
const app = createApp(App);
app
  .use(ElementPlus)
  .use(bus)
  .use(store)
  .use(VueI18n)
  .use(router)
  .component('svg-icon', SvgIcon)
  .mount('#app');


