// const nettyUrl = "ws://13.231.143.111:9180/social"
// const marketUrl = "ws://54.64.75.109:9280/market"
// const upLoadOssUrl = "http://13.231.143.111:9119/api/web/common/oss/upload"

const nettyUrl = "ws://georgeroberts.asuscomm.com:9180/social"
const marketUrl = "ws://georgeroberts.asuscomm.com:9280/market"
const upLoadOssUrl = "https://api.fingertrading.com/api/web/common/oss/upload"
const loginUrl = "http://trade.fingertrader.com"
const signUpUrl = "https://api.fingertrader.com/registered"
export default {
  nettyUrl,
  upLoadOssUrl,
  marketUrl,
  loginUrl,
  signUpUrl
};