
import request from "../request"


export function sendCaptchaEmail(email) { //验证
  return request({
    url: '/api/web/common/email/sign-up-code/' + email,
    method: 'post',
  })
}
export function userSendCaptchaEmail(email) { //验证
  return request({
    url: '/api/web/common/email/send-verify-code/' + email,
    method: 'post',
  })
}

export function getCountry() { //验证
  return request({
    url: '/api/web/common/country/list',
    method: 'get',
  })
}

export function getLocation() { //验证
  return request({
    url: '/api/web/common/country/getLocation',
    method: 'get',
  })
}

export function getLocationByIp(ip) { //验证
  return request({
    url: '/api/web/common/country/getLocationByIp/' + ip,
    method: 'get',
  })
}

export function getCityByCountryId(countryId) { //验证
  return request({
    url: '/api/web/common/city/cityList/' + countryId,
    method: 'get',
  })
}

export function getDictById(id) { //輸入id == 1 可獲得所有數據字典的類別，再根據回傳的id可以找到該類別的細項列表
  return request({
    url: '/api/web/common/dict/getDictById/'+ id,
    method: 'get',
  })
}

export function getDictByParentId(id) { 
  return request({
    url: '/api/web/common/dict/listByParentId/'+ id,
    method: 'get',
  })
}

export function getNoticeByType(page,limit,isReadAndTypeObj) { 
  return request({
    url: '/api/web/common/notice/list/'+page+"/"+limit,
    method: 'get',
    params: isReadAndTypeObj
  })
}

export function deleteById(id) { 
  return request({
    url: '/api/web/common/notice/deleteById/'+ id,
    method: 'post',
  })
}

export function readById(id) { 
  return request({
    url: '/api/web/common/notice/readById/'+ id,
    method: 'post',
  })
}

export function deleteOssImage(url) { 
  return request({
    url: '/api/web/common/oss/remove',
    method: 'delete',
    data: url
  })
}