export const lang = {
    //navbar
    openaccount: 'オープン口座',
    login: 'ログイン',
    service: 'サービス',
    product: '製品',
    support: '支援',
    download: 'て負荷',

    //Tab Bar
    statementline1: 'Finger Trading是由George Home Ltd开发维护，和Jet Profit Group(AU)共同经营的一款线上金融信息及交易软件，旨在为全球的投资人提供更多的可选性，及优质的服务。',
    statementline2: '我们的收入来源为广告和商家的API使用及维护费，我们不会加收您的任何费用。',
    statementline3: '本公司不提供任何的交易产品，您的资金及交易均由接入的金融服务商API/接口完成，你在操作Finger的同时，也可以直接下载并登陆金融服务商的交易系统进行交易。',
    statementline4: '任何声明的证明文件和统计数据均将根据要求提供。显示的交易代码仅作演示计，不旨在构成任何推荐。',
    statementline5: '在线交易股票、期权、期货、外汇、外国证券和固定收益产品可能存在巨大损失风险。',
    statementline6: '期权交易涉及风险，并非适合所有投资者。更多信息，请阅读"标准期权的特征和风险"。索取复印本，请访问',
    statementline7: '保证金交易仅适用于可承受高风险的专业投资者。您的损失可能会大于您的初始投资。有关保证金借贷利率的详细信息，请参见',
    statementline8: '证券期货具有高风险，并非适合所有投资者。您的损失可能会大于您的初始投资。开始交易证券期货之前，请阅读证券期货风险披露声明。',
    statementline9: '外汇交易存在高风险损失。外汇交易的结算日期可能因不同时区和银行节日而改变。当跨外汇交易市场交易时，可能需要借贷资金来结算外汇交易。当计算跨市场交易的交易成本时，借贷资金的利率也是必须要考虑的因素。',
    statementline10: '有关Jet Profit Group的信息，请参见',
    statementline11: '有关George Home的信息，请参见',
    statementline12: '以下是已经接入Finger API的金融服务提供商：',
    statementline13: '你可以在系统中切换你想要的服务商为你提供服务，同时你也可以跳过Finger直接通过他们的交易软体进行操作',
    tabmenu_title1: '账户',
    tabmenu_title2: '支持',
    tabmenu_title3: '相关服务',
    tabmenu_title4: '软件&下载',
    tabmenu_title5: '关注我们',
    accounttype: '账户类型',
    freeuse: '免费试用',
    console: '相关控制台',
    graneback: '技术反馈',
    personaluser: '个人用户',
    Institutionalusers: '机构用户',
    broker: '经纪人',
    tradingsystem: '交易软件',
    relateddocuments: '相关文档',
    APIaccess: 'API接入',

    //index_line1
    index_line_1_title: 'あなたと一緒に世界のチャンスを見つけましょう',
    index_line_1_text: '単一の統合アカウントから、株式、オプション、先物、通貨、債券、ファンドにグローバルに投資します。複数の通貨で口座に資金を投入し、複数の通貨建ての資産を取引します。 24時間年中無休で市場データにアクセスします。',
    markets: '市場数',
    countries: '国の数',
    currencies: '通貨品種',
    //INDEX-line2
    SafetyAndStability: 'Safety And Stability',
    LowCosts: 'Low Costs',
    GlobalAccess: 'GlobalAccess',
    PremierTechnology: 'Premier Technology',
    SafetyAndStabilityText: 'Our closed development model ensures the security of your capital and transactions.',
    LowCostsText: 'Our transparent, low commissions and financing rates minimize costs to maximize returns.',
    GlobalAccessText: 'Invest globally in stocks, options, futures, currencies, bonds and funds from a single integrated account.',
    PremierTechnologyText: "Finger's powerful suite of technology helps you optimize your trading speed and efficiency and perform sophisticated portfolio analysis.",
    //index-line3
    Fingeristhekey: 'Finger is the key to the World’s Markets',
    GlobalAPIProgram: 'Global API Program',
    IntegratedCashManagement: 'Integrated Cash Management',
    EffectiveSocial: 'Effective Social',
    line3_text_1: '我们的API计划不断的受到全球金融服务提供商的关注和加入，任何地区的不同类型金融机构可以通过我们提供的API不断的加入我们的平台，为您提供最新最快的金融服务及信息',
    line3_text_2: '我们是一个公开的平台，你可以把我们理解为一个超级市场，而金融商为供货商，为了获得我们的产品展出和流量，他们不得不提供更有竞争力的价格',
    line3_text_3: '我们也针对银行提供了我们的API接口，所以我们的现金管理是一体化的，您的资金会通过银行之间的API自动以最快的速度去到您需要的地方',
    line3_text_4: '我们的产品设计是封闭式的，并且您的操作每一步都需要通过7层验证才会进入与金融商的API验证，它保证了您的交易安全和信息安全',
    line3_text_5: '我们提供了高效的用户社交功能，同时也有大量的专业人士活跃在我们的平台，并开放了新闻API接口为您提供最新的全球信息',
    line3_text_6: '我们在全球有多个有丰富经验的技术团队作为支持，专业高效的为您提供全方位的服务，如果您有针对服务的意见，可以通过点击与我们联系',
    //index-line4
    OurPartners: 'Our Partners',
    //index-line5
    line5_text1: '我非常喜欢FingerTrading带给我的方便，一个账户就能买到全球的股票，未来我非常看到印度的经济，并想购入印度的优良资产，FingerTrading有效的帮助我在伦敦也能随时买入和卖出，并且随时能和印度的投资者交流沟通，能有效的了解印度的真实情况，并且交到了很多优质的朋友，现在Finger已经成为我生活中不可或缺的一部分了。',
    line5_text2: '我每天需要动很多手术，工作十分繁忙，完全没有时间去完成繁琐的开户和过多的操作，Finger Trading帮助了我，提交一次资料就在全球都开了户，我能很方便的交易任何我想交易的 产品，同时简单的操作也让我能很快的上手，我爱FInger Trading！！！',
    line5_text3: '我是一名投资银行的投资经理，每天与这些数据和信息打交道，以前我需要去很多不同的网站，下载很多不同的APP去收集信息，但自从加入了Finger，我感觉我的手指可以触摸都世界 任何角落，我能安心的在我美国的办公室里操作亚洲的投资，上午还在亚洲证券市场，下午也许会去欧洲，又可能回来美国，FInger帮助了我！',
    line5_text4: '我是一个艺术工作者，生活在伦敦，发达地区的投资机会已经十分有限了，世界上每个国家和地区所走的路都差不多，先是欧美，再是亚洲，资本和机会都在不停的转动，我一直在想 能够投资那些新闻里的发展中国家，比如中国和印度，我相信他们能帮助我提升我的财富。',

    //Login View
    next: '次のステップ',
    learn: 'もっと理解する',
    signin: 'ログイン',
    signup: '新規登録',
    CreateAccount: 'アカウントを作成',
    oruseaccount: 'または、アカウントを使用してください',
    Email: 'Eメール',
    password: 'パスワード',
    forgotpassword: 'パスワードをお忘れですか？',
    hello: 'こんにちは、友よ',
    enteryourdetails: '初めてジンドゥーをご利用される方はこちらからご登録ください',
    WelcomeBack: 'お帰りなさい!',
    KeepConnected: 'アカウントをお持ちの方はこちらからログインしてください',
    Oruseyouremailforregistration: 'または、登録にメールを使用します',
    erroremail1: "请输入邮箱地址",
    erroremail2: "请输正确的邮箱格式:xxx@xxx.xxx",
    errorpassword1: "请输入您的密码",
    errorpassword2: "至少8个字符，至少1个大写字母，1个小写字母和1个数字,不能包含特殊字符",
    errorpassword3: "两次输入不一样!请重新确认",
    //Service
    Taptonext: "次のページをクリックしなさい",
    whatcanido: 'サービス案内',
    titleService_1_1: "私は投資家です",
    titleService_1_2: "私は金融機関です",
    titleService_1_3: "私はディーラーです",
    titleService_1_4: "ソフトウェアを購入したい",
    titleService_2_1: 'Fingerを通じてグローバルに投資したい',
    titleService_2_2: 'Fingerを通じて世界中の顧客にサービスを提供したい',
    titleService_2_3: 'Fingerが特定の領域で宣伝するのを手伝いたい',
    titleService_2_4: "ソフトウェアを購入したい",
    titleService_3_1: "会員登録はたったの3ステップ, 「指で世界を保持します」",
    titleService_3_2: '当社のブランドをグローバルに宣伝し、世界のほとんどの地域のお客様にサービスを提供するためにご参加ください',
    titleService_3_3: '私たちはあなたのために良い販売計画を準備しました',
    titleService_3_4: 'FInger Tradingは、金融サービスで10年の経験があり、世界中の400を超える金融機関にソフトウェアと技術サポートを提供しています。',
    //Support
    KNOWLEDGEBASE: '基本知識',
    BROKERS: 'ディーラー',
    FINANCINGINSTITUTION: '金融機関',
    CONTACTUS: 'お問い合わせ',
    Howhelpyou: 'どういうご用件ですか?',
    Topics: 'ヘルプカテゴリ',
    GeneralandFAQs: 'よくある問題',
    Service: 'サービス',
    Products: '製品',
    Feesandprices: '料金と価格',
    MyAccount: 'アカウント',
    CheckoutandBill: '支払いと請求',
    Relevantlaws: '関連法',
    APIrules: 'APIルール',
    Contactus: 'お問い合わせ',
    Yourquestion: 'あなたの質問は何ですか',
    Search: '検索する',
    //Product
    //nav
    Stocks: '株式',
    Options: 'オプション',
    Funds: '基金',
    Bonds: '債券',
    Futures: '先物取引',
    SpotCurrencies: '為替',
    Metals: '貴金属',
    ETFs: 'ETFs',
    HedgeFunds: 'ヘッジファンド',
    Portfolios: 'ポートフォリオ',
    //Stocks
    Useyourfinger: '指を使って世界を習得する!',
    Stockline_1: 'ここで世界的に有名な会社の株を買うことができます',
    Stockline_2: 'ここでは、世界中からニュースを入手できます',
    Stockline_3: 'ここであなたの投資はグローバルに自由に投資できます',
    Stockline_4: 'ここでは、グローバルな証券会社からのハイエンドサービスを楽しむことができます',
    Stockline_5: 'ここでは、低い取引コストを楽しむことができます',
    Stockline_6: 'ここでは、最も安全な情報サービスを楽しむことができます',
    Stockline_7: 'ここでは、最も専門的なデータ指標を使用できます',
    Slideyourfinger: '指をスワイプして参加してください',
    JoinUs: '参加しませんか',
    Important: '予防：',
    ImportantText: '取引は各国の規制に準拠している必要があります。取引ルールと費用は異なります。特定のルールと費用については、各国の関連規制を参照してください。取引する業者は自由に選択できます。',
    //stocks-line-2
    USAmarkets: 'USA markets',
    AUmarkets: 'AU markets',
    JPmarkets: 'JP markets',
    USAmarketsText1: '我们接入的是美国著名券商Interactive Brokers的API,您的交易将通过IB完成以低至美国股票每股$0.005（全包）的佣金交易股票。',
    USAmarketsText2: '以每1,000美元8美分左右（全包）的成本获得最佳价格执行。',
    USAmarketsText3: '通过我们的股票收益提升计划将您全额持有的股票出借给其他交易者，从而创造收益。',
    AUmarketsText1: '我们接入的是澳大利亚著名券商Jet Profit(AU)的API,您的交易将通过JP完成',
    AUmarketsText2: '澳大利亚股票每股$0.005（全包）的佣金交易股票。',
    AUmarketsText3: '以每1,000美元8美分左右（全包）的成本获得最佳价格执行。',
    JPmarketsText1: '我们接入的是日本著名券商野村证券的API,您的交易将通过IB完成',
    JPmarketsText2: '以低至日本股票每股$0.005（全包）的佣金交易股票。',
    JPmarketsText3: '以每1,000美元8美分左右（全包）的成本获得最佳价格执行。',
    //OPTION
    option_title1: '支付更低的佣金，',
    option_title2: '获取潜在更高的收益!',
    option_title3: '对于美国期权，我们的佣金为每份合约$0.15到$0.70美元',
    option_title4: '低频和高频交易者可自选计费方式：',
    option_title5: '固定式 – 低频交易者可选择按合约支付低廉的固定佣金，该佣金已包括所有费用。',
    option_title6: '阶梯式 – 活跃交易者可选择按交易支付更低佣金，且可能因较大的交易量获得交易所折扣。',
    //FUTURE
    future_title1: '广泛的产品和地理覆盖面',
    future_title2: '您可在北美、欧洲和亚洲35多个金融市场使用以',
    future_title3: '下期货合约对冲头寸风险或从价格变动中获利：',
    future_title4: '农业——畜牧、谷物及其它货币',
    future_title5: '软性大宗商品——咖啡、白糖、可可及其它',
    future_title6: '能源——原油、天然气及其它',
    future_title7: '金属——贵金属、基础金属及其它',
    future_title8: '股票指数',
    future_title9: '波动率指数',
    

    //Warning Risk
    RiskWarning: '风险提示',
    Warning1: '您的账户默认情况下不会开通高风险或其他杠杆类产品，您需要额外开通功能',
    Warning2: '诸如：期货，期权，外汇等交易属于保证金类杠杆交易，在您开通交易功能前必须按照美国法律阅读《风险揭示书》并签署',
    Warning3: '同时您必须成为根据美国证券交易委员会(SEC)的规则被认定为认可投资者和合格购买者',
    Warning4: '您需要根据法律填写您的投资经历和资产情况通过资格认定，我们才能为您开通此类服务',
    Warning5: '根据产品的地区，您可能需要满足当地法律要求才能进行购买',
    Warning6: '如果需要帮助，您可以联系我们的客服人员',
    //Funds
    Funds1: '26,450 funds from around the world',
    Funds2: 'The Interactive Brokers Mutual Fund Marketplace provides our clients around the world with a large availability of fundsat low cost. The Marketplace offers access to 26,450 no-load funds from over 285 fund families, can be accessed by clients from over 200 countries and territories and includes many prominent fund families, including funds from Allianz, American Funds, BlackRock, Fidelity, Franklin Templeton, Invesco, MFS and PIMCO.',
    Funds3: 'Low Costs',
    Funds4: 'Unlike most firms, Interactive Brokers never charges a custody fee.',
    Funds4_1: 'Over 8,300 funds are available with no transaction fees, with fee funds outside the US charging EUR 4.95 (or equivalent).',
    Funds4_2: 'The Marketplace is fund-agnostic – there is no conflict of interest as we don’t offer proprietary funds.',
    Funds4_3: 'Fund value is included in equity with loan value to increase client margin buying power.',
    Funds5: 'New to buying funds?',
    Funds6: 'This guide shows you how to choose funds, cut costs and build your own portfolio.',
    Funds7: 'Important information',
    Funds8: "Unlike cash, the value of investments, and any income from them, can fall as well as rise so you could get back less than you invest. Our service is designed for investors who are happy making their own investment decisions. If you're unsure of the suitability of an investment please seek advice. Tax rules can change and the value of any benefits depends on your personal circumstances.",
    //Bonds
    Bonds1: 'Use Our Free Bond Search Tool and See How Much You Can Save',
    Bonds2: 'Enjoy low and fully transparent commissions on bonds, with no mark-up on bond prices. Access a full universe of US government securities,26,356 corporate bonds, 993,411 municipal securities and 25,069 CDs.',
    Bonds3: 'Depth and Breadth of Offering',
    Bonds4: 'Finger offers multiple fixed income bonds to meet your trading needs. Trade corporate, municipal, or treasury bonds all from a single integrated account and single trading screen.',
    Bonds5: 'Self-Service Access',
    Bonds6: 'Finger offers direct market access to US, Canadian and European corporate bonds, US government securities, US Municipal bonds, 1,900+ non-US Sovereign bonds in Asia and Europe, Euro-denominated government bonds, fixed income futures and fixed income options, all from a single integrated account.',


}
