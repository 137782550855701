<template>
  <div class="locationbar">
    <div class="bar">
      <div class="languageBtn btn" @click="clickBtn()">
        <svg-icon name="earth" class="languageIcon" size="18" />
        <div class="text"><h5>ENG/中</h5></div>
      </div>
      <div class="languageBtn btn">
        <svg-icon name="location" class="languageIcon" size="18" />
        <div class="text">
          <h5 v-if="this.location.country">
            {{ this.location.city }} / {{ this.location.country }}
          </h5>
          <h5 v-else>无法获取您的国家</h5>
        </div>
      </div>
    </div>

    <div class="menuItem" :class="{ isActive: isActive }">
      <transition-right-enter>
        <div class="content" v-if="isActive">
          <div class="titleBox">
            <div class="text">
              <h1>选择语言</h1>
            </div>
            <div class="btnClose btn" @click="clickBtn">
              <svg-icon name="xmark" class="languageIcon" size="28" />
            </div>
          </div>

          <div class="menuBox">
            <div
              class="languageBtn btn"
              v-for="item in languageMenu"
              :key="item"
              @click="changeLang(item)"
            >
              <svg-icon :name="item.icon" class="languageIcon" size="28" />
              <div class="text">
                <h4>{{ item.text }}</h4>
              </div>
            </div>
          </div>
        </div>
      </transition-right-enter>
    </div>
  </div>
</template>

<script>
import TransitionRightEnter from '@/assets/animation/TransitionRightEnter.vue'
import { getLocationByIp } from '@/axios/api/common'

export default {
  name: 'LocationBar',
  components: {
    TransitionRightEnter
  },
  data () {
    return {
      isActive: false,
      location: "",
      currentLang: 'uk',
      languageMenu: [
        { icon: "usa", text: "US ENGLISH", locale: "en", currentLang: "uk" },
        { icon: "uk", text: "UK ENGLISH", locale: "en", currentLang: "uk" },
        { icon: "japan", text: "JAPANESE", locale: "jp", currentLang: "uk" },
        { icon: "taiwan", text: "TAIWAN", locale: "tw", currentLang: "taiwan" },
        { icon: "china", text: "CHINESE", locale: "cn", currentLang: "uk" },
      ],
    }
  },
  methods: {
    clickBtn () {
      this.isActive = !this.isActive
    },
    getUserLocation () {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(response => {
          this.results = response.ip;
        })
      getLocationByIp()
    },
    changeLang (item) {

      this.$i18n.locale = item.locale
      this.currentLang = item.currentLang
      console.log(this.$i18n.locale)
    },

  },
  created () {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(response => {
        const ip = response.ip;
        getLocationByIp(ip).then(res => {
          this.location = res.data.list
        })
        if (this.location.country == "Japan") {
          this.currentLang = 'Japanese'
        }
      })
  },

}
</script>

<style lang='scss' scoped>
.isBlock {
  transform: translateY(-30px);
}
.locationbar {
  transition: 0.4s;
  width: 100vw;
  min-height: 30px;
  background: #fff;
  padding: 0 15%;
  .bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .languageBtn {
      padding: 4px 10px;
      border-radius: 4px;
      margin-left: 60px;
      min-width: 80px;
      justify-content: center;
      .languageIcon {
        color: rgb(78, 78, 78);
        margin-right: 6px;
      }
    }
  }

  .menuItem {
    max-height: 0;
    transition: 0.8s;
    overflow: hidden;
    .titleBox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btnClose {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        justify-content: center;
      }
    }
    .menuBox {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      margin: 10px 0;
      .languageBtn {
        padding: 4px 10px;
        border-radius: 4px;
        margin-left: 60px;
        min-width: 240px;
        height: 40px;
        border: 1px solid rgb(219, 219, 219);
        .languageIcon {
          color: rgb(78, 78, 78);
          margin-right: 6px;
          width: 40px;
          margin-right: 20px;
        }
      }
    }
  }
}
.btn {
  display: flex;
  align-items: center;
  margin-left: 60px;
  transition: 0.4s;
  &:hover {
    background: rgb(194, 194, 194);
    cursor: pointer;
  }
}
.isActive {
  max-height: 1000px !important;
  padding: 20px 0;
}
@media screen and (max-width: 1050px) {
  .locationbar {
    padding: 0 2%;
    .menuBox {
      display: grid;
      grid-template-columns: 1fr 1fr !important;
      margin: 10px 0;
      .languageBtn {
        padding: 4px 10px;
        border-radius: 4px;
        margin-left: 10px;
        min-width: 160px !important;
        height: 40px;
        border: 1px solid rgb(219, 219, 219);
        .languageIcon {
          color: rgb(78, 78, 78);
          margin-right: 6px;
          width: 40px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
