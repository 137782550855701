import { createStore } from 'vuex';

// import storage from "@/utils/storage"

export default createStore({
  //全局状态
  state: {
    //账户表格
    accountForm: {},
  },
  //加工State
  getters: {
  },
  //改变State状态
  mutations: {
    //删除state this.$store.commit('remover', 'key1');
    remover (state, key) {
      state[key] = '';
    },
    //更新state this.$store.commit('upDate', {key1: 'value3'});
    upDate (state, obj) {
      let key = Object.keys(obj)[0];
      state[key] = obj[key];
    },
    update (state, obj) {
      let key = Object.keys(obj)[0];
      state[key] = obj[key];
    },
    //添加state信息
    add (state, obj) {
      let key = Object.keys(obj)[0];
      let objNew = obj[key];
      let objOld = state[key]
      state[key] = Object.assign(objOld, objNew);
    },
  },
  actions: {
  },
  modules: {
  },
});
