<template>
  <svg
    class="svg-icon"
    :style="{
      width: props.size + 'px',
      height: props.size + 'px',
      color: props.color,
    }"
    @mousedown="clickIcon"
  >
    <use :xlink:href="`#icon-${props.name}`" :fill="props.color" />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true,
      default: "email"
    },
    size: {
      type: String,
      default: "26"
    },
    color: {
      type: String,
    }
  },
  setup (props) {
    return {
      props
    };
  }
});
</script>