<template>
  <div class="main" :class="{ isBlock: isBlock }">
    <location-bar />
    <div id="navbar">
      <div class="logoBox" @click="clickLogo()">
        <svg-icon name="logo" size="40" class="logoImg" />
        <div class="logoText">Finger</div>
      </div>
      <div class="menu" :class="{ isShowMenu: isShowMenu }">
        <div class="menuItem" v-for="item in menuList" :key="item">
          <div
            class="text"
            :class="{ menuItemAvtive: navActive == item.text }"
            @click="goPath(item)"
          >
            <h4>{{ item.text }}</h4>
          </div>
          <div
            class="animation start-home"
            :class="{ navActive: navActive == item.text }"
          />
          <div class="subMenu">
            <div
              class="subMenuItem"
              v-for="subItem in item.subMenu"
              :key="subItem"
              @click="goSubPath(item, subItem)"
            >
              <div class="subMenuText">
                {{ subItem.text }}
              </div>
              <div class="description">
                {{ subItem.description }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btnBox">
        <div class="btn" @click="goLogin()">{{ $t('lang.login') }}</div>
        <div class="btn" @click="goSignUp()">{{ $t('lang.joinUs') }}</div>
      </div>

      <div class="hamburgerMenu" @click="showMenu()">
        <div class="ham">
          <div class="line" :class="{ lineAni1: isShowMenu }" />
          <div class="line" :class="{ lineAni1: isShowMenu }" />
          <div class="line" :class="{ lineAni3: isShowMenu }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationBar from '@/components/locationBar/LocationBar.vue';
import UrlUtils from '@/utils/url';
export default {
  name: 'NavBar',
  components: {
    LocationBar
  },
  data () {
    return {
      isSearch: false,
      scrollTop: 0,
      isBlock: false,
      navActive: "Home",
      isShowMenu: false,
      isMenuOpen: false
    }
  },
  methods: {
    // 保存滚动值，这是兼容的写法
    handleScroll () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop > 20) {
        this.isBlock = true
      } else {
        this.isBlock = false
      }
    },
    // 滚动条回到顶部
    backTop () {
      if (this.scrollTop > 10) {
        document.documentElement.scrollTop = 0
      }
    },
    goPath (item) {
      this.$router.push(item.path)
      this.navActive = item.text
      this.isShowMenu = false
    },
    clickLogo () {
      this.$router.push("/")
    },
    goSubPath (item, subItem) {
      this.$router.push(item.path + subItem.path)
      this.navActive = item.text
      this.isShowMenu = false
    },
    goLogin () {
      window.location.href = UrlUtils.loginUrl
    },
    goSignUp () {
      window.location.href = UrlUtils.signUpUrl
    },
    showMenu () {
      this.isShowMenu = !this.isShowMenu
    },
    showMenuOpen () {
      this.isMenuOpen = !this.isMenuOpen
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  unmounted () {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    menuList () {
      return [
        { text: this.$t('lang.navMenu.home'), path: "/" },
        {
          text: this.$t('lang.navMenu.social'), path: "/community", subMenu: [
            { text: this.$t('lang.navMenu.news'), path: "/news", description: "Finger 整合了众多新闻api为你提供最及时的新闻内容" },
            { text: this.$t('lang.navMenu.flash'), path: "/flash", description: "你可以在Finger的社交平台分享交易生活中的一切" },
            { text: this.$t('lang.navMenu.report'), path: "/report", description: "参考专业的技术分析报告，全方位了解市场" },
          ]
        },
        {
          text: this.$t('lang.navMenu.service'), path: "/service", subMenu: [
            { text: this.$t('lang.navMenu.forInvestor'), path: "/", description: "我们对接了全世界不同交易所的上万种交易商品" },
            { text: this.$t('lang.navMenu.forBroker'), path: "/broker", description: "为经纪人，我们提供了完整的营销方案及财务后台，并同时向全球的经纪商申请了最优惠的折扣" },
            { text: this.$t('lang.navMenu.forAnalyst'), path: "/analyst", description: "经营您的账号，我们提供了完善的付费观看功能" },
            { text: this.$t('lang.navMenu.forBrokerageHouse'), path: "/brokerageHouse", description: "通过API,我们为经纪商提供了可定制的交易服务" },
            { text: this.$t('lang.navMenu.forDeveloper'), path: "/developer", description: "我们为开发者提供了众多api,让你可以灵活的开发出各式各样的应用" },
          ]
        },
        { text: this.$t('lang.navMenu.download'), path: "/download" },
        {
          text: this.$t('lang.navMenu.support'), path: "/support", subMenu: [
            { text: this.$t('lang.navMenu.general'), path: "/", description: "一般问题" },
            { text: this.$t('lang.navMenu.develop'), path: "/develop", description: "开发者支持" }
          ]
        },
      ]
    }
  }
}

</script>

<style lang='scss' scoped>
.isBlock {
  transform: translateY(-30px);
  transition: 0.4s;
}
.main {
  position: fixed;
  top: 0;
  z-index: 9999999;
}

#navbar {
  position: relative;
  width: 100vw;
  height: 60px;
  width: 100vw;
  display: grid;
  grid-template-columns: 4fr 3fr 2fr;
  align-items: center;
  padding: 0% 14%;
  background: rgb(160, 59, 59);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  .logoBox {
    display: flex;
    align-items: center;
    .logoImg {
      @include color('nav-logo');
    }
    .logoText {
      font-family: 'Fredoka';
      @include color('nav-logo-text');
      font-size: 28px;
      margin-left: 10px;
    }
  }
  .hamburgerMenu {
    display: none;
  }
  .menu {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: rgb(210, 214, 217);
    .isShowMenu {
      display: none;
    }
    .menuItem {
      width: 110px;
      transition: 0.4s;
      .text {
        font-size: 16px;
        font-weight: 900;
        z-index: 1;
      }
      &:hover {
        cursor: pointer;
      }
      &:hover .text {
        @include color('nav-logo-text');
      }
      &:hover .subMenu {
        max-height: 1000px !important;
      }
      &:hover .subMenuItem {
        animation: moveIn 0.4s ease-out;
      }
      .animation {
        position: absolute;
        height: 2px;
        bottom: 10px;
        z-index: 0;
        background: #fca311;
        border-radius: 2px;
        width: 0;
        margin-left: 10px;
        transition: all 0.4s ease 0s;
      }
      .navActive {
        width: 90px !important;
      }
      .menuItemAvtive {
        @include color('nav-logo-text');
      }
    }
    .subMenu {
      position: absolute;
      width: 100vw;
      max-height: 0px;
      overflow: hidden;
      background: rgb(160, 59, 59);
      top: 60px;
      transition: 0.4s;
      left: 0;
      padding: 0px 14%;
      .subMenuItem {
        display: flex;
        align-items: center;
        &:hover {
          @include color('nav-logo-text');
          cursor: pointer;
        }
        .subMenuText {
          position: relative;
          z-index: 99999;
          height: 40px;
          text-align: start;
          padding-left: 30px;
          line-height: 40px;
          width: 210px;
          transition: 0.4s;
          border-right: 1px solid rgb(124, 121, 121);
        }
        .description {
          padding-left: 40px;
          font-size: 14px;
          color: rgb(206, 200, 200);
        }

        .subAnimate {
          animation: moveIn 0.4s ease-out;
        }
        &:nth-child(1) {
          padding-top: 10px;
        }
        &:nth-last-child(1) {
          padding-bottom: 10px;
        }
      }
    }
  }
  .btnBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0 20px;
    .btn {
      word-wrap: break-word;
      padding: 6px 20px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 900;
      border-radius: 8px;
      transition: 0.4s;
      word-wrap: break-word;
      &:nth-child(1) {
        background: #fff;
        color: #000;
      }
      &:nth-child(2) {
        background: #000;
        color: #fff;
      }
      &:nth-child(1):hover {
        cursor: pointer;
        color: rgb(40, 134, 55);
        background: rgb(217, 217, 217);
      }
      &:nth-child(2):hover {
        cursor: pointer;
        color: rgb(40, 134, 55);
        background: rgb(55, 55, 55);
      }
    }
  }
}
@keyframes moveIn {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateX(-10px);
    opacity: 0.7;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 1050px) {
  #navbar {
    padding: 0%;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    grid-template-columns: none;
    .logoBox {
      margin-left: 20px;
    }
    .isShowMenu {
      display: block !important;
    }
    .menu {
      position: absolute;
      background: #000;
      height: 100%;
      display: none;
      top: 60px;
      width: 100vw;
      height: 100vh;
      .menuItem {
        height: auto;
        align-items: center;
        text-align: center;
        line-height: 60px;
        width: 100%;
        border-bottom: 1px solid #43403c;
        &:hover .subMenu {
          max-height: 1000px !important;
        }
        &:hover .subMenuItem {
          animation: moveIn 0.4s ease-out;
        }
      }
      .subMenu {
        position: relative !important;
        width: 100vw;
        max-height: 0px;
        overflow: hidden;
        background: rgb(160, 59, 59);
        transition: 0.4s;
        padding: 0px 4%;
        top: 0;
        .subMenuItem {
          display: flex;
          align-items: center;
          .subMenuText {
            position: relative;
            z-index: 99999;
            height: 40px;
            text-align: start;
            padding-left: 10px;
            line-height: 40px;
            align-items: center;
            width: 140px;
            transition: 0.4s;
            border-right: 1px solid rgb(124, 121, 121);
          }
          .description {
            padding-left: 20px;
            line-height: 20px;
            text-align: start;
            font-size: 12px;
            color: rgb(206, 200, 200);
          }
        }
      }
    }
    .btnBox {
      display: flex;
    }
    .logoText {
      display: none;
    }
    .hamburgerMenu {
      margin-right: 30px;
      width: 60px;
      height: 60px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      color: #fff;
      z-index: 999;
      .line {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: #cdcdcd;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      }
      .lineAni1:first-child {
        transform: rotate(45deg) translate(0px, -2px);
      }

      .lineAni1:nth-last-child(2) {
        transform: rotate(0deg) scale(0.2, 0.2);
        opacity: 0;
      }
      .lineAni3 {
        transform: rotate(-45deg) translate(0px, -2px);
      }
    }
  }
}
</style>
